/*******************************************************************************
 *
 * Loading Spinners
 *
 ******************************************************************************/

.#{$prjPrefix}-loading-spinner {

    //color: $text-color;
    color: $color--loading-indicator;
    font-size: 30px;
    text-align: center;

    //rounded Edges in Form Controls
    .form-group &,
    .input-group & {
        border-radius: $border-radius-base;
    }

    // Small Spinner for Forms
    //--------------------------------------------------
    &--small {

        & .#{$prjPrefix}-loading-spinner {

            &__icon {
                @include Media(md, min) {
                    margin-top: -20px;

                    font-size: 40px;
                }
            }
        }
    }

    // Large Spinner for Walls
    //--------------------------------------------------
    &--large {

        & .#{$prjPrefix}-loading-spinner {

            &__icon {
                @include Media(md, min) {
                    margin-top: -40px;

                    font-size: 80px;
                }
            }
        }
    }

    // Overlay
    //--------------------------------------------------
    &--overlay {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 3;

        background-color: rgba($body-bg, 0.8);
        & .#{$prjPrefix}-loading-spinner {

            &__icon {
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                margin-top: -15px;
            }
        }
    }
    &--overlay#{&}--gray {
        background-color: fade($gray-lighter, 80%);
    }
}

