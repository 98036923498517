/*******************************************************************************
 *
 * Main SCSS File
 *
 * Includes All Sub-less-files of the Project including Bootstrap
 *
 * TABLE OF CONTENT
 *
 * BLOCKS:
 *      Includes Stylings of different Sections
 *
 * BOOTSTRAP:
 *      Bootstrap Framework
 *
 * BOOTSTRAP-EXTENDS:
 *      Extension Classes and Overrides
 *
 * COMPONENTS:
 *      Contains UI Components
 *
 * ADDITIONAL GLOBAL BLOCKS
 *      Optional Block Components, that are locasted in global Style Directory
 *#
 ******************************************************************************/

// Module Vars
//--------------------------------------------------
$moduleName:    'ugc';


// Stylings for data-ng-cloak directive
//--------------------------------------------------
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}


/*------------------------------------------------------*\
     Functions
\*------------------------------------------------------*/
@import "../../../global/src/sass/functions.scss";



/*------------------------------------------------------*\
    Configuration
\*------------------------------------------------------*/
@import "../../../global/src/sass/_configurations/variables.scss";
@import "../../../global/src/sass/_configurations/bootstrap-theming";


/*------------------------------------------------------*\
    Bootstrap - Include
\*------------------------------------------------------*/
@import "../../../global/src/sass/bootstrap.scss";


/*------------------------------------------------------*\
     Module - Includes
\*------------------------------------------------------*/
@import "../../../global/src/sass/mixins.scss";
@import "../../../global/src/sass/components.scss";


/*------------------------------------------------------*\
     Bootstrap - Extensions (after because of Module Mixin usage)
\*------------------------------------------------------*/
@import "../../../global/src/sass/bootstrap-extends.scss";

/*------------------------------------------------------*\
     Additional Global Blocks for Module
\*------------------------------------------------------*/
@import "../../../global/src/sass/_components/_blocks/wall.scss";
@import "../../../global/src/sass/_components/_blocks/wall-header.scss";
@import "../../../global/src/sass/_components/_blocks/wall-actionbar.scss";
@import "../../../global/src/sass/_components/_blocks/wall-signup.scss";
@import "../../../global/src/sass/_components/_blocks/wall-popup.scss";
@import "../../../global/src/sass/_components/_blocks/wall-loader.scss";
@import "../../../global/src/sass/_components/_blocks/item.scss";
@import "../../../global/src/sass/_components/_blocks/item-counters.scss";
@import "../../../global/src/sass/_components/_blocks/video-thumb.scss";
@import "../../../global/src/sass/_components/_blocks/media-sizer.scss";
@import "../../../global/src/sass/_components/_blocks/bar-nav.scss";
@import "../../../global/src/sass/_components/_blocks/actions.scss";
@import "../../../global/src/sass/_components/_blocks/comment.scss";
@import "../../../global/src/sass/_components/_blocks/user.scss";

@import "../../../global/src/sass/_components/_directives/ct-state-class.scss";




/*------------------------------------------------------*\
    Specific Module Includes
\*------------------------------------------------------*/
@import "components.scss";
