$color-state-new: #428bca;
$color-state-blocked: #d9534f;
$color-state-active: transparent;

.ct-resource-state {
    display: none;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 0;

    border: 2px dashed;

    &__message {
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;

        text-align: center;

        font-size: 12px;
        line-height: 28px;
    }

    &--active {
        border-color: $color-state-active;
    }
    &--active &__message {
        background-color: $color-state-active;
        color: contrast($color-state-active);
    }

    &--new {
        display: block;
        border-color: $color-state-new;
    }
    &--new &__message {
        background-color: $color-state-new;
        color: contrast($color-state-new);
    }

    &--blocked {
        display: block;
        border-color: $color-state-blocked;
    }
    &--blocked &__message {
        background-color: $color-state-blocked;
        color: contrast($color-state-blocked);
    }
}
