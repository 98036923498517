/*******************************************************************************
 *
 * Checkbox Component
 *
 ******************************************************************************/

.#{$prjPrefix}-checkbox {
    position: relative;
    color: $color--input;
    width: $checkbox__size;
    height: $checkbox__size;
    background-color: $background--input;

    line-height: 1;

    @include e('input') {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
    }

    &::before {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: .1rem solid;
    }

    &::after {
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
        font-family: FontAwesome;
        pointer-events: none;
    }

    @include m('checked') {
        &::after {
            content: '\f00c';
        }
    }

    @include m('disabled') {
        background-color: $input-bg-disabled;
    }

}

