/*******************************************************************************
 *
 * Minimal Configuration
 *
 * Bootstrap Variables Overrides happens also here
 *
 * For further Customization:
 *
 *      You have to copy the Value you want, out of the Bootstrap variables
 *      and set it here with the new Value
 *
 ******************************************************************************/


/*------------------------------------------------------*\
     Globals
\*------------------------------------------------------*/
@import "color-mapping";


// Font Weights
//--------------------------------------------------
$light:     300                                         !global;
$normal:    400                                         !global;
$semibold:  600                                         !global;
$bold:      700                                         !global;
$extrabold: 800                                         !global;
$bolder:    $extrabold                                  !global;

/*------------------------------------------------------*\
     Bootstrap Variables
\*------------------------------------------------------*/
$border-radius-base:        $border-radius--base !global;
$border-radius-large:       $border-radius--large !global;
$border-radius-small:       $border-radius--base !global;

$font-size-base:          12px !global;
$font-size-large:         16px !global; // ~16px
$font-size-small:         10px !global; // ~12px
$font-size-xl:          ceil(($font-size-base * 1.7)) !global; // ~24px
$button__fontsize--fb:              18px;
$button__fontsize--mobile--fb:      12px;

$input__height--base:       34px                            !global;
$input__height--med:        43px                            !global;
$input__height--large:      60px                            !global;

$button__height--base:      $input__height--base            !global;
$button__height--med:       $input__height--med             !global;
$button__height--large:     $input__height--large           !global;

$padding-base-vertical:     ceil(($input__height--base - $font-size-base) / 2) !global;
$padding-base-horizontal:   12px !global;

$padding-large-vertical:    ceil(($input__height--med - $font-size-large) / 2) !global;
$padding-large-horizontal:  16px !global;

$padding-xl-vertical:    13px !global;
$padding-xl-horizontal:  16px !global;

$button__padding-y--base--fb:       ((($button__height--med - $button__fontsize--fb ) / 2) - 1);
$button__padding-y--base--mobile--fb:       ((($button__height--med - $button__fontsize--mobile--fb) / 2) - 1);

//$text-color:            $color--text--base !global;
$font-size-base:    $text--size--base   !global;

$font-family-sans-serif:  $text--family, "Helvetica Neue", Helvetica, Arial, sans-serif !global;
$font-family-base:        $font-family-sans-serif !global;

$modal-backdrop-opacity:      .8 !global;

// Bootstrap Variable override for mixins
//--------------------------------------------------
$input-height-base: $input__height--base    !global;
$input-height-large: $input__height--med    !global;

$input-color:       $color--input !global;

$input-border:                   $input-color;
$input-border-focus:             rgba($input-border, 0.6) !global;
$input--shadow:                 inset 1px 1px 2px rgba(0,0,0, .15) !global;


$btn-font-weight:            $normal !global;

$btn-cta-color:              $color--button--cta !global;
$btn-cta-bg:                 $background--btn--cta !global;
$btn-cta-border:             #ea1e26 !global;

$btn-text:                   $color--text--light;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base:            $radius--btn--base !global;
$btn-border-radius-large:           $radius--btn--large !global;
$btn-border-radius-small:           $btn-border-radius-base !global;

$input-border-radius:               $radius--input--base !global;
$input-border-radius-large:         $radius--input--large !global;
$input-border-radius-small:         $input-border-radius !global;


$link-color: $color--link--base;
$link-hover-color: lighten($color--link--base, 10%);
$link-hover-decoration: none !global;









