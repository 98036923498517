/*******************************************************************************
 *
 * Wall Container
 *
 ******************************************************************************/

.#{$prjPrefix}-wall {

    padding: $grid-gutter-width 0;
}
