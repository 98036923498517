/*******************************************************************************
 *
 * Frontend Moderation Menu Stamp Directive Stylings
 *
 ******************************************************************************/

.ct-directives__mod-stamp {
    position: relative;
    text-align: center;

    transition: all 0.4s ease-in-out;

    color: $color--text--base;

    @include Media(md, min) {
        position: absolute;
        right: 0;
    }

    [ng-click], [data-ng-click] {
        cursor: pointer;
    }

    //
    //** The Icon in the Flow. triggers the dropdown on Hover

    &__trigger {
        position: relative;
        font-size: 30px;
        z-index: 1;
        text-align: center;
        transition: transform 0.3s ease-out;


        &:hover {
            transform: scale(0.98, 0.98);
        }
    }


    //
    //** The Menu, which will be shown on hovering over the Trigger
    &__menu {

        background-color: #fff;
        position: relative;
        z-index: 2;
        width: 100%;

        font-size: 16px;
        line-height: (36/12);
        text-align: left;

        border: solid $text-color--base;
        border-width: 1px 0 1px 0;

        @include Media(md, min) {
        position: absolute;
        top: 30px;
        right: 30px;

        border-width: 0;
        box-shadow: 2px 2px 2px rgba(150, 150, 150, 0.7);
        width: 160px;
        font-size: 12px;
    }

        @include Media(sm, max) {

        //margin-top: -30px;
        }

        // Used for a more forgiving Handle with the mouseover Event
        // (on Desktop)
        &:before {
            @include Media(md, min) {
            content: '';
            position: absolute;
            display: block;
            left: -40px;
            right: -40px;
            bottom: -40px;
            top: -30px;
            z-index: -1;
        }
        }

        // Caption of Menu
        &__title {
            font-weight: $bold;
            text-transform: uppercase;
            padding: 0 7px;

            > i {
                font-size: 16px;
            }

            &__text {
                position: relative;
                top: 1px;
            }

        }

        // The List Wrapper
        &__list {

            margin-bottom: 30px;

            @include Media(md, min) {
            margin: 0;
        }

            // The List - Elements
            &__item {
                border-bottom: 1px dotted $gray-light;
                &:last-child {
                    border-bottom: none;
                }
            }
        }

        // Menu Items
        &__link {
            display: block;
            padding: 0 7px;
            color: $text-color;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: $brand-yellow-light;
                color: $brand-primary;
            }
        }
    }

}
