/*******************************************************************************
 *
 * Wall Like Loading Component
 * Shows Loading State in Wall Modules like UGC or Commitment
 *
 ******************************************************************************/

.#{$prjPrefix}-wall-loader {

    color: $color--loading-indicator;
    font-size: 20px;
    text-align: center;

    @include Media(md, min) {
        font-size: 50px;
    }
}
