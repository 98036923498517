/*******************************************************************************
 *
 * Base Class For Module Items (e.g.: UGC - Wallitem, CC - Wall Item)
 *
 ******************************************************************************/

.#{$prjPrefix}-item {
    // Container of The Item. Normally it is an link item ion a Wall, that contains the Item
    //--------------------------------------------------
    &__wrapper {
        display: block;
        width: 100%;
        overflow: hidden;   //due to collapsing margins
        margin-bottom: 2rem;

        &:hover {
            text-decoration: none;
        }
    }

    &__stamp {
        position: absolute;
        top: 0;
        left: 0;
    }
    
    
    /*------------------------------------------------------*\
         Item Content starts here
    \*------------------------------------------------------*/
    background-color: $background--item;
    color: $color--text--base;
    font-size: $font-size-base;

    position: relative;
    overflow: hidden;
    z-index: 0;     //Needs a z-index, that hover works correctly with border radius

    border-radius: $base-radius;

    /*------------------------------------------------------*\
         Font Size Setter for Generic Text Elements
    \*------------------------------------------------------*/
    &__headline {
        font-size: (18/12)#{em};
        font-weight: $bold;
        line-height: (21 / 18);

        margin-bottom: 1em;
    }

    &__text {
        font-size: (16/12)#{em};
        line-height: (24 / 16);
    }



    /*------------------------------------------------------*\
         Hover Component
    \*------------------------------------------------------*/
    &__hover {
        pointer-events: none;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        background-color: $background--item--hover;
        color: $color--item--hover;
        opacity: 0;

        transition: opacity $transition--duration--base ease-out;
        visibility: hidden;

        @include smmax() {
            display: none;
        }

        // Hover Icon
        //--------------------------------------------------
        &__icon {
            display: block;
            font-size: (60 / 12)#{em};

        }

        // Default Hover centers a Search Icon
        // (Used if no specific Component Hover Style available)
        //--------------------------------------------------
        @include m(default) {

            @include e(icon) {
                position: absolute;
                top: 50%;
                left: 50%;

                transform: translate(-50%, -50%);

            }
        }


    }


    /*------------------------------------------------------*\
         States
    \*------------------------------------------------------*/
        // Hover
        //--------------------------------------------------

        &:hover &__hover {
            opacity: 1;
            visibility: visible;
        }
}
