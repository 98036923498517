/*******************************************************************************
 *
 * Functions Include
 *
 * Need to be put on top of includes, because they can be used in Bootstrap Variables
 *
 ******************************************************************************/

/**
 * Return an asset path
 */
@function asset($path, $module: 'global') {
    @return '../../../'+$module+'/'+$path;
}

@import "_functions/backend-functions";
@import "_functions/get-vw";
@import "_functions/map-to-string";
@import "_functions/tint";
@import "_functions/shade";
@import "_functions/calc-percent";
@import "_functions/sqrt";
@import "_functions/selectortostring";

@import "_functions/shortnames";
