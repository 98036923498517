/*******************************************************************************
 *
 * Adds a gradient to a Button
 *
 ******************************************************************************/


@mixin btn-gradient-apply($from, $to) {
    @include gradient-vertical($from, $to);

    &:hover, &:focus {
        @include gradient-vertical(darken( $from, 10%), darken($to, 10%));
    }
}
