/**
 * Layout Component for the Disclaimer, that needs to be accepted via Checkbox
 * ct-checkbox-disclaimer
 */

.#{$prjPrefix}-checkbox-disclaimer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    @include e('checkbox') {
        margin-right: 2rem;
        flex-shrink: 0;
        flex-grow: 0;
    }

    @include e('disclaimer') {
        flex-grow: 1;
    }
}
