/*******************************************************************************
 *
 * Counters in Wall Items e.g.: SHare, Like, Votes
 *
 ******************************************************************************/


/*------------------------------------------------------*\
     Container List for all COunters
\*------------------------------------------------------*/
.#{$prjPrefix}-item-counters {

}


/*------------------------------------------------------*\
     Single Counter
\*------------------------------------------------------*/
.#{$prjPrefix}-item-counter {
    font-size: $font-size-base;
}
