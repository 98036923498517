/*******************************************************************************
 *
 * Collapsy Styles.
 * Temporary for Transition Setting on Icon, until Animation not solved by JS
 *
 ******************************************************************************/

.is-collapsed {
    transform: rotateX(180deg);
}



[ct-collapsy-indicator] {
    transition: all .5s ease-out;
}
