/************************************************************************************************************************
 *
 * Styling for the Media Drop Directive (Image Drag and Drop)
 *
 ************************************************************************************************************************/

.mediaDrop {

    font-size: 12px;
    line-height: 14px;

    @include Media(sm, max) {
        margin-bottom: 15px;
        width: 100%;
    }

    p {
        margin: 0;
    }

    .btn {

        @include Media(md, min) {
            line-height: 1;
            padding: 6px 4px;
            border: 0;
        }

        @include Media(sm, max) {
            padding: $padding-base-vertical $padding-base-horizontal;
            font-size: 16px;
        }
    }

    &--large {
        font-size: 16px;
    }

    .btn-file {
        position: relative;
        overflow: hidden;

        input[type=file] {
            position: absolute;
            top: 0;
            right: 0;
            min-width: 100%;
            min-height: 100%;
            font-size: 100px;
            text-align: right;
            filter: alpha(opacity=0);
            opacity: 0;
            outline: none;
            background: white;
            cursor: inherit;
            display: block;
        }
    }

    /**
     * Content Area
     **/

    & &--content {

        position: relative;
        overflow: hidden;

        border: 1px dashed;
        background-color: $background--media-drop;
        color: $color--media-drop;

        margin-left: auto;
        margin-right: auto;

        @include Media(xs, max) {
            display: none;
        }
    }

    & &--height-adjustment {

    }

    /**
     * Drop Zone Styling
     **/
    &--dropzone,
    &--dropzone-helper {

        background-color: #FFFFFF;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;

        opacity: 0.8;

        &-wrapper {

        }

        &--table {
            display: table;
            padding: 10px;
            width: 100%;
            height: 100%;
        }

        &--content {
            display: table-cell;
            border: 2px solid $brand-primary;

            text-align: center;
            vertical-align: middle;

            > i {
                color: $brand-primary;
                font-size: 50px;
            }

            > p {
                font-size: 24px;
            }
        }
    }

    &--dropzone-helper {

        &--content {
            border: 2px dashed $brand-primary;
        }
    }

    /**
     * Cropper before Image Upload
     **/
    & &--addMedia {

        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 100%;

        &-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            text-align: center;
            width: 100%;
            height: 100%;
        }

        &-buttons {

        }

        /**
         * Mobile Stylings
         **/
        &--mobile {
            padding-left: 5px;
        }
    }

    & &--image {

        width: 100%;
        height: 100%;
        overflow: hidden;

        //Image Positioning
        text-align: right;

        &-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            width: 100%;
            height: 100%;
            margin: 0 auto;
        }

        > img {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            margin: auto;

            &.img-landscape {
                width: 100%;
                height: auto;
            }

            &.img-portrait {
                width: auto;
                height: 100%;
            }
        }

        &-cropper {

            overflow: hidden;
            position: relative;
            height: 100%;

            .img-landscape {
                width: auto;
                height: 100%;
                cursor: ew-resize;
            }

            .img-portrait {
                width: 100%;
                height: auto;
                cursor: ns-resize;
            }

            &:hover {

                .indicator {
                    display: none;
                }
            }
        }

        &-buttons {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    /**
     * Indicatior Overlay
     **/

    .indicator {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0.25);

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

        pointer-events: none;

        > i {
            color: #FFFFFF;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            width: 30px;
        }
    }

    /**
     * Stylings when Image is croppable
     **/
    .mediaDrop--useCropper#{&} {

        //mediaDrop--useCropper.mediaDrop
        & {
            .mediaDrop--content {

                @include Media(md, min) {
                    margin: 0 auto;
                }
            }
        }

        /**
         * Inner Elements
         **/
        & .mediaDrop {

            &--image {
                &-wrapper {
                    margin: 0 auto;
                }

                > img {
                    &.img-landscape {
                        width: auto;
                        height: 100%;
                    }

                    &.img-portrait {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

    }

    //Behaviour, when Image is set
    &--hasImage &--content {
        @include Media(xs, max) {
            display: block;
        }
    }


    /*------------------------------------------------------*\
         Size Modifiers
    \*------------------------------------------------------*/
    @include m(large) {

        .btn {

            @include Media(md, min) {
                line-height: 1;
                padding: 6px 3px;
                border: 0;
                margin-top: .5rem;

                .fa {
                    font-size: 2em;
                }
            }
        }
    }
}
