/*******************************************************************************
 *
 * Text Content Styles
 *
 ******************************************************************************/


.#{$prjPrefix}-content {

    &__title {
        color: $color-content__title;
        font-size: (18 / 16)#{em};
    }
}
