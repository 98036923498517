/*******************************************************************************
 *
 * Main Signup for Wall like Modules
 *
 ******************************************************************************/


.#{$prjPrefix}-wall-signup {
    background-color: $background--signup;

    position: absolute;
    left: 0;
    width: 100%;
    z-index: $zindex-modal - 10;




    // Form Wrapper
    //--------------------------------------------------
    &__form {
        padding: $grid-gutter-width 0;
    }

    // Form Control Modifications
    //--------------------------------------------------
    &__textarea {
        resize: none;

        @include mdmin {
            min-height: 108px;
        }
    }

    // Disclaimer Style
    //--------------------------------------------------
    &__disclaimer {
        padding-top: 1rem;
    }


    // Tag List Styles
    //--------------------------------------------------
    &__tags {
        padding: 0 .3rem;

        // Full Width Elements in Line
        //--------------------------------------------------
        &__loading,
        &__custom {
            width: 100%;
        }


        // Custom Tag Input
        //--------------------------------------------------
        &__custom {
            margin-top: 1rem;
            width: 100%;
        }
    }
}
