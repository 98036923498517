/*******************************************************************************
 *
 * Component that scales Media Items on Viewport
 *
 ******************************************************************************/


.#{$prjPrefix}-media-sizer {
    position: relative;
    height: 100vh;


    // Media Item
    //--------------------------------------------------
    &__item {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        max-width: 90%;
        max-height: 90%;
        margin: auto;
    }

    // Playbutton or other
    //--------------------------------------------------
    &__icon {

    }
}
