.#{$prjPrefix}-wall-actionbar {

    background: transparent;


    &__signup {
        position: relative;

        height: 60px;
        max-height: 60px;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 60px;

        border-top-right-radius: 6px;
        border-top-left-radius: 6px;

        box-shadow: none;

        text-transform: uppercase;
        font-weight: bold;

        .fa {
            transition: transform .2s ease-out;
        }
        &--open {
            .fa {
                transform: rotate(-135deg);
            }
        }

    }
}
