/*******************************************************************************
 *
 * Maps the Colors of the Cambuildr to Theme Variables
 * and sets other Element Base Values
 * (Actually overwrites Vars of variables file. )
 *
 ********************************************x**********************************/

/*------------------------------------------------------*\
     Path Vars
\*------------------------------------------------------*/
$globalPath: '../../../global/build';

$blue-dark: #1F2954;
$blue-med: rgb(8, 80, 168);
$blue-light: mix(#fff, #1F2954, 94%);

/*------------------------------------------------------*\
     Fill base Vars with Placeholder Tokens of DB
\*------------------------------------------------------*/
$module-bg-color:           #1F2954;
$emphasize-color:           rgb(8, 80, 168);
$item-bg-color:             #FFFFFF;
$cta-color:                 #EE4D53;
$btn-text-color:            #FFFFFF;
$inputfields-color:         #1F2954;
$text-color--base:          #1F2954;
$signup-bg-color:           #A6C1E0;

$base-radius:               0;         //add unit to string explicitly. This has to be done on every function call
$font-family:               "Helvetica Neue LT Std";





/*------------------------------------------------------*\
     Mapping of Base Vars to Element Variables
\*------------------------------------------------------*/
$text--family:               $font-family;                               //Base Font Family of Module
$text--size--base:           12px;                                       //Base Font Family of Module

$background--module:        $module-bg-color;                           // Background Color of Module
$background--header:        $background--module;                        // Background Color of Header
$background--signup:        $signup-bg-color;                           // Background Color of Signup Form
$background--popup:         $item-bg-color;                        // Background Color of Wall Popup
$background--barnav:        $item-bg-color;                        // Background Color of Bar Navigation e.g.: Popup
$background--item:          $item-bg-color;                             // Background Color of UGC (Story) / Commitment
$background--item--hover:   rgba($item-bg-color, .7);      // Background Color of UGC (Story) / Commitment
$background--input:         #ffffff;                                       // Background Color of Input Elements
$background--btn--cta:      $cta-color;                                 // Background Color of Primary Buttons
$background--media-drop:    rgba($inputfields-color, .15); // Background Color of media Drop
$background--quote:         rgba($emphasize-color, .9);    // Background Color of  Quote Component
$background--modal:         $background--signup;                        // Background Color of  Modals
$background--nav:           $background--item;                          // Background Color of  Navigation
$background--progress-bar:        $background--module;                           // Progressbar Background
$background--answer:        $emphasize-color;                           // UGC Answer BG


$color--text--base:         $text-color--base;                          // Base Text Color
$color--text--light:        rgb(166,166,166);                           // Base Text Color
$color--link--base:         #06a0dd;

$color--header--base:       $emphasize-color;                           // Text Color in Header with no Picture set
$color--header--img:        #fff;                                       // Text Color in Header with Picture set
$color--input:              $inputfields-color;                         // Text Color in Header with Picture set
$color--input__placeholder: $color--input;                              // Text Color in Header with Picture set
$color--button--base:       #fff;                                       // Text Color of Default Buttons
$color--button--cta:        $btn-text-color;                            // Text Color of CTA Buttons
$color--button--text:       $color--text--base;                         // Text Color of Text Buttons (e.g.: Abbrechen)
$color--disclaimer:         $color--text--base;                         // Text Color of Disclaimer
$color--item--base:         $color--text--base;                         // Text Color of UGC (Story) / Commitment
$color--item--hover:        $emphasize-color;                        // Text Color in Content Hover Element
$color--play-icon:          $background--item;                        // Text Color in Content Hover Element
$color--loading-indicator:  $color--text--base;                         // Text Color of Loading Indicator in Wall Modules
$color--media-drop:         $inputfields-color;                         // Text Color of media Drop
$color--quote:              $cta-color;                                 // Text Color of  Quote Component
$color--action-item:        $color--text--light;                        // Text Color of Text Buttons
$color--barnav:             $emphasize-color;
$color--indicator:          $emphasize-color;                           // Text Color Active Indicator
$color--user:               $color--text--base;                           // User Component Color
$color-content__title:      $module-bg-color;                           // Text Color For Title in Popups etc.
$color-popup__placeholder:  rgba($background--popup, 0.4); // Placeholder Icon Color
$color-rating:              rgba($color--text--base, 0.6); // Text Color for Rating Directive (Starts)
$color--main-logo:          $emphasize-color;                           // Logo Color in Navigation
$color--nav:                $color--main-logo;                           // Navigation Item Color
$color--progress-bar:        $color--link--base;                           // Progressbar TextColor
$color--answer:             $background--item;                           // UGC Answer Text Color


$border-radius--base:   $base-radius;
$border-radius--large: $base-radius * 1.5;

/*------------------------------------------------------*\
    Radius Settings
\*------------------------------------------------------*/
$radius--item:              $border-radius--base;           // Radius of Content - items
$radius--input--base:             $border-radius--base;           // Radius of Input Elements
$radius--input--large:             $border-radius--large;           // Radius of Input Elements
$radius--btn--base:               $border-radius--base;           // Radius of Button Elements
$radius--btn--large:               $border-radius--large;           // Radius of Button Elements

/*------------------------------------------------------*\
     Static Grayshades
\*------------------------------------------------------*/
$gray--lighter:             rgb(229,229,229);

/*------------------------------------------------------*\
     Global Transition + Animation Settings
\*------------------------------------------------------*/
$transition--duration--base:    .2s !default;

/*------------------------------------------------------*\
     Module Specific Vars
\*------------------------------------------------------*/
$color--com-item:           $emphasize-color    !default;


/*------------------------------------------------------*\
     Demo Tools Variables
\*------------------------------------------------------*/
$brand-blue-dark:       rgb(0, 48, 91);
$brand-blue-light:      rgb(0, 159, 227);
$brand-yellow-light:    rgb(255, 255, 233);

/*------------------------------------------------------*\
     Other Global Colors
\*------------------------------------------------------*/
// Social Colors
//--------------------------------------------------
$brand-fb:                              rgb(43, 65, 112)        !default;
$brand-fb--light:                       rgb(59, 89, 152)        !default;
$brand-tw:                              rgb(0, 159, 227)        !default;
$brand-whatsapp:                        rgb(77, 194, 71)        !default;
$brand-instagram:                       rgb(155, 114, 63)        !default;



/*------------------------------------------------------*\
     Navigation + Logo
\*------------------------------------------------------*/
$nav__height:                            6rem !default;
$nav__color--border:                    $color--main-logo !default;
$nav__width--border:                    .2rem  !default;
$nav__font-size--desktop:               1.8rem !default;
$nav__font-size--mobile:                1.2rem !default;
$logo__font-size--desktop:               2.4rem !default;
$logo__font-size--mobile:                1.8rem !default;

/*------------------------------------------------------*\
     Advocacy Specific
    TODO: needs to moved to other color file others is bootstrap specific
\*------------------------------------------------------*/
$font--main: $text--family;

// Theme Colors
//--------------------------------------------------
$theme--yellow:     rgb(255, 219, 47);
$theme--blue--dark:     rgb(4, 56, 116);
$theme--blue--light:     rgb(0, 159, 227);


$form__placeholder__color: $color--input;

// E - Mail - Form
//--------------------------------------------------
$form-heading__color:  $color--header--base;
$form-heading__bg:  $background--signup;
$form__bg:  $background--signup;


// Email - Preview
//--------------------------------------------------
$email-preview__background:  $background--item;
$email-preview__color:       $color--text--base;


// Progressbar
//--------------------------------------------------
$pb-height: 30px;

//Old One
/*$pb-color--bg: $background--input;
$pb-color--primary: $emphasize-color;
$pb-color--border: $pb-color--primary;*/

$pb-color--bg: $background--progress-bar;
$pb-color--primary: $color--progress-bar;
$pb-color--border: $pb-color--primary;
$pb-border--style: solid;

$pb-border--width: 1px;
$pb-border--color: $pb-color--border;


$pb-spacing--left: 50px;
$pb-spacing--left--mobile: 20px;
$pb-font-size: 14px;
$pb-transition-dur: .6s;
$pb-transition-stagger: .3s;


/*------------------------------------------------------*\
     Voting
\*------------------------------------------------------*/
$vt-color--answer--base:                $color--text--base !default;                  //Voting Answer Text Color
$vt-color--answer--active:              $emphasize-color !default;                  //Voting Answer Text Color Voted / Hover
$vt-color--answer__border:              rgba($vt-color--answer--base, 0.3) !default;    //Voting Answer Border Color
$vt-color--answer--image--hover:        $btn-text-color      !default;    //Text Color of Hover Icon on Answers with Image
$vt-color--answer--image__result:        $vt-color--answer--image--hover      !default;    //Text Color Result Text on Answers with Image


$vt-bg--answer__bar:                    rgba($vt-color--answer--active, 0.1) !default;    //Voting Answer Result Bar Color (No Image)
$vt-bg--answer--image--hover:           rgba($vt-color--answer--active, 0.7) !default;    //Background Color of Hover on Answers with Image
$vt-bg--answer--image__result:        $vt-color--answer--image--hover      !default;    //Background Color Result Text on Answers  with Image
$vt-bg--answer--image__bar:                 rgba($vt-color--answer--active, 0.3) !default;    //Voting Answer Result Bar Color (Image)
$vt-bg--answer--image--active:              rgba($module-bg-color, 0.5) !default;                  //Background Color for Active Answer with Image
$vt-bg--answer--image--active__bar:         rgba($vt-color--answer--active, 0.6) !default;    //Voting Answer Result Bar Color (Image) Active State


/*------------------------------------------------------*\
     Additional Buttons
\*------------------------------------------------------*/
$btn--submit__background:  $form__bg;
$btn--submit__color:  #fff;
$btn--submit__radius:  $border-radius--base;

$btn--outline__color: $module-bg-color;


/*------------------------------------------------------*\
     Userbar Settings
\*------------------------------------------------------*/
$userbar__height:   3.5rem;


/*------------------------------------------------------*\
     States
\*------------------------------------------------------*/
$state--error:    rgba(221,0,0, 1) !default;
$state--success:    rgba(68,185,0, 1) !default;


/*------------------------------------------------------*\
     Forms
\*------------------------------------------------------*/
$checkbox__size:                    2rem                !default;
$radio__size:                       $checkbox__size     !default;
