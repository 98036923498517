/*******************************************************************************
 *
 * Label-Hide Directive Styles
 *
 ******************************************************************************/

.label-hide {
    position: relative;

    //
    //** Input Fields
    .control-label {
        position: absolute;
        left: $padding-base-horizontal;
        top: 50%;

        //Ensure that the Label is above input-groups
        z-index: 3;
        transform: translateY(-50%);

        color: $color--input__placeholder;
        font-weight: $normal;

        font-size: 16px;

        max-height: calc(100% - #{2*$padding-base-vertical});
        overflow: hidden;

        opacity: .6;


        // Large Form Modifier
        //--------------------------------------------------
        @at-root.form-group-lg#{&} {
            left: $padding-large-horizontal;
            padding-right: $padding-large-horizontal;
        }


        // Has-feedback for label-hide fields
        //--------------------------------------------------
        @at-root.has-feedback#{&} {
            & ~ .form-control-feedback {
                top: 0;
            }
        }
    }

    & textarea {
        &.form-control {

            ~ .control-label {
                top: $padding-base-vertical;
                transform: translate(0, 0);
            }
        }

        @at-root .form-group-lg#{&} {
            &.form-control {

                ~ .control-label {
                    top: ($padding-large-vertical + 2);
                }
            }
        }
    }
}
