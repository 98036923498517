/*******************************************************************************
 *
 * Mixin Aliases for faster Use
 *
 ******************************************************************************/


/*------------------------------------------------------*\
     Media Queries
\*------------------------------------------------------*/

@mixin xsmin() {

    @include Media(xs, min) {
        @content
    }
}
@mixin smmin() {

    @include Media(sm, min) {
        @content
    }
}
@mixin mdmin() {

    @include Media(md, min) {
        @content
    }
}
@mixin lgmin() {

    @include Media(lg, min) {
        @content
    }
}


@mixin smmax() {

    @include Media(sm, max) {
        @content
    }
}


@mixin xsmax() {

    @include Media(xs, max) {
        @content
    }
}


/*------------------------------------------------------*\
     BEM
\*------------------------------------------------------*/

@mixin b($name) {
    @include block($name) {
        @content
    };
}


@mixin e($name) {
    @include element($name) {
        @content
    };
}


@mixin m($name) {
    @include modifier($name) {
        @content
    };
}
