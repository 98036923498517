/*******************************************************************************
 *
 * Specific Components for CC Module
 *
 ******************************************************************************/


@import "_components/ugc-item.scss";
@import "_components/wall.scss";
@import "_components/wall-header.scss";
@import "_components/wall-actionbar.scss";
