/*******************************************************************************
 *
 * Popup of Wall Item Module (UGC, CC)
 *
 ******************************************************************************/

/*------------------------------------------------------*\
     Whole Modal
\*------------------------------------------------------*/
.#{$prjPrefix}-wall-popup {
    font-size: 1.6rem;

    width: 100%;
    overflow: hidden;


    // Image, Video or icon
    //--------------------------------------------------
    &__media {
        position: relative;

        @include smmax() {
            margin: ($grid-gutter-width * -1) (70px * -1) $grid-gutter-width;
        }

        @include xsmax() {
            margin: ($grid-gutter-width * -1) ($grid-gutter-width * -1) $grid-gutter-width;

        }

        @include mdmin {
            height: 100vh;
        }

    }

    // Popup image
    //--------------------------------------------------
    &__image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin: auto;

        max-width: 100%;
        max-height: 100%;

        // specific Inline Image Styles
        //--------------------------------------------------
        &--inline {

                width: 200px;
                height: auto;
        }
    }

    // Placeholder Icon
    //--------------------------------------------------
    &__placeholder {
        color: $color-popup__placeholder;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include smmin {
            font-size: 200px;
        }

        @include mdmin {
            font-size: 300px;

        }
    }




    // Colored Popup Content Part
    //--------------------------------------------------
    &__content {
        background-color: $background--popup;
        height: 100vh;      //full Viewport height
        overflow: auto;     // scrollable
        overflow-x: hidden;
    }




    // A Content Section in the Popup. Can be nested for indentions
    //--------------------------------------------------
    &__section {
        padding: $grid-gutter-width;

        @include mdmin() {
            padding-right: 0;

        }

        // Larger Padding on main content
        //--------------------------------------------------
        &--outer {
            position: relative;

            @include smmin {
                padding: $grid-gutter-width 70px;
                padding-bottom: 0;
            }
        }

        &--large {
            position: relative;

            @include smmin {
                padding: $grid-gutter-width 100px;
            }
        }


        // Answer
        //--------------------------------------------------
        @include m(answer) {
            background-color: $background--answer;
            color: $color--answer;
        }
    }




    // Specific Popup Signup Form Styles
    //--------------------------------------------------
    &__form {

        // Textfield Media Drop Alignment
        //--------------------------------------------------
        &__textarea {
            resize: none;

            @include smmin {
                min-height: 226px;
            }

            @include mdmin {
                min-height: 104px;
            }

            @include lgmin {
                min-height: 156px;
            }
        }
    }

    &__message {
        margin-bottom: 40px;
    }

    &__author {
        &__name {
            &#{&} {
                color: $emphasize-color;
                font-weight: bold;
                font-size: 16px;
            }
        }
    }


    // Moderation Stamp Positioning
    //--------------------------------------------------
    &__moderation-stamp {

        @include smmax() {
            margin: 0 (70px * -1) $grid-gutter-width;
        }

        @include xsmax() {
            margin: 0 ($grid-gutter-width * -1) $grid-gutter-width;

        }
    }
}
