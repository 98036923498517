/*******************************************************************************
 *
 * Key Visual Component
 *
 ******************************************************************************/

.#{$prjPrefix}-main-logo {
    display: flex;
    align-items: center;

    color: $color--main-logo;

    line-height: 1;
    font-size: $logo__font-size--mobile;
    font-weight: $bold;
    text-transform: uppercase;

    height: 100%;

    @include mdmin() {
        font-size: $logo__font-size--desktop;
    }


    // Logo Image Hidden on Normal State
    //--------------------------------------------------
    @include e(kv) {
        display: none;
        height: 100%;
        width: auto;
    }

    // If a Key Visual is set
    //--------------------------------------------------
    @include m(has-image) {

        @include e(brand-name) {
            display: none;
        }


        @include e(kv) {
            display: block;
        }

    }

}
