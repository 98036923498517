.#{$prjPrefix}-questionwall-header {
    position: relative;
    color: #0850a8;
    z-index: 0;


    @include e('video') {
        display: block;
        width: 100%;
        height: auto;
    }

    video::-webkit-media-controls {
        display: none;
    }

    @include e('actionbar') {
        position: relative;
        z-index: 2;

        @include mdmin() {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }
    }

    @include e('container') {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;

        z-index: 1;
    }


    @include e('info') {
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        width: 100%;
        color: #fff;
        text-align: center;

        @include smmin() {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__title {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.2;

        @include smmin {
            font-size: 20px;
        }

        @include mdmin {
            font-size: 40px;
        }
    }
    &__text {
        font-weight: normal;
        font-size: 16px;
        line-height: 1.2;


        @include smmin {
            font-size: 20px;
        }

        @include mdmin() {
            font-size: 40px;
        }
    }



    &__links {
        position: absolute;
        right: 0;
        top: 20px;
        font-size: 14px;
        color: #fff;

        &__link {
            position: relative;
            z-index: 1;
            padding: 10px 12px;
            transition: all 0.4s ease-out;
            color: #fff;

        }
    }
}
