/*******************************************************************************
 *
 * Rating Component
 *
 ******************************************************************************/


.#{$prjPrefix}-rating {
    display: inline-block;

    color: $color-rating;
    font-size: 1.6rem;

    // Icon
    //--------------------------------------------------
    &__icon {
        display: inline-block;
    }




    // Smaller icons
    //--------------------------------------------------
    &--small {
        font-size: 1.2rem;
    }

    // Large Icons
    //--------------------------------------------------
    &--large {
        font-size: 2rem;
    }
    
    // Colored Icons for viszualizing interaction
    //--------------------------------------------------
    &--input {
        color: $color--input;
    }
}
