/*******************************************************************************
 *
 * Mixin for clearer Structuring og BEM Modifier + Elements
 *
 ******************************************************************************/

/*------------------------------------------------------*\
     Config
\*------------------------------------------------------*/
$elementSeparator:  '__'    !default;
$modifierSeparator: '--'    !default;



/*------------------------------------------------------*\
     Needed Functions
\*------------------------------------------------------*/

// Checks if the selector contains a modifier or not
//--------------------------------------------------
@function containsModifier($selector) {

    $selector: selectorToString($selector);

    @if str-index($selector, $modifierSeparator) {
        @return true;
    }
    @else {
        @return false;
    }
}

// Get parent block
//--------------------------------------------------
@function getBlock($selector) {

    $selector: selectorToString($selector);
    $modifierStart: str-index($selector, $modifierSeparator) - 1;

    @return str-slice($selector, 0, $modifierStart);
}




/*------------------------------------------------------*\
     Actual BEM Mixins
\*------------------------------------------------------*/

// Creates Block
//--------------------------------------------------
@mixin block($block) {
    .#{$block} {
        @content;
    }
}

// Creates Element in Block
//--------------------------------------------------
@mixin element($element) {
    $selector: &;

    @if containsModifier($selector) {

        $block: getBlock($selector);

        @at-root {
            #{$selector} {
                #{$block+$elementSeparator+$element} {
                    @content;
                }
            }
        }
    }
    @else {
        @at-root {

            #{$selector+$elementSeparator+$element} {
                @content;
            }
        }
    }

}


// Adds Modifier
//--------------------------------------------------
@mixin modifier($modifier) {

    @at-root {

        #{&}#{$modifierSeparator+$modifier} {
            @content
        }
    }

}
