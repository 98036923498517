/*******************************************************************************
 *
 * Directive: Selecty Styles
 *
 *******************************************************************************/

$selecty__trigger-width: $input-height-large;
$selecty__text-color: $color--input;


.ct-selecty {
    position: relative;
    padding-right: $selecty__trigger-width;

    // Active Select Label "quasi"
    //--------------------------------------------------
    &__selected {

    }

    &__select select {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        width: 100%;
        height: 100%;

        border: none; //This needs to be set - otherwise the height will not change!

        opacity: 0;

        &:hover {
            cursor: pointer;
        }
    }

    // Trigger with small Borderl
    //--------------------------------------------------
    &__trigger {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        margin: 4px 0;
        border-left: 1px solid rgba($selecty__text-color, 0.2);
        width: $selecty__trigger-width;

        // Trigger Icon
        //--------------------------------------------------
        &:before {
            display: block;
            content: "▾";

            position: absolute;
            top: 50%;
            left: 0;
            right: 0;

            text-align: center;

            transform: translate(0, -50%);
        }
    }
}

