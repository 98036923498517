/*******************************************************************************
 *
 * Cuts Edge off of Container
 *
 ******************************************************************************/

@mixin cutEdges($background: transparent, $edgeSize: 5px, $inverted: false, $side: both) {

    @if $side == left {

        background: $background;
        background: linear-gradient(135deg, transparent $edgeSize, $background 0) top left,
        linear-gradient(45deg, transparent $edgeSize, $background 0) bottom left;
        background-size: 100% 52%;
        background-repeat: no-repeat;

    } @else if $side == right {

        background: $background;
        background: linear-gradient(-135deg, transparent $edgeSize, $background 0) top right,
        linear-gradient(-45deg, transparent $edgeSize, $background 0) bottom right;
        background-size: 100% 52%;
        background-repeat: no-repeat;

    } @else {

        @if $inverted == false {
            background: $background;
            background: linear-gradient(135deg, transparent $edgeSize, $background 0) top left,
            linear-gradient(-135deg, transparent $edgeSize, $background 0) top right,
            linear-gradient(-45deg, transparent $edgeSize, $background 0) bottom right,
            linear-gradient(45deg, transparent $edgeSize, $background 0) bottom left;
            background-size: 52% 52%;
            background-repeat: no-repeat;

            /*&:hover {
                background: darken($background, 10%);
                background: linear-gradient(135deg, transparent $edgeSize, darken($background, 10%) 0) top left,
                linear-gradient(-135deg, transparent $edgeSize, darken($background, 10%) 0) top right,
                linear-gradient(-45deg, transparent $edgeSize, darken($background, 10%) 0) bottom right,
                linear-gradient(45deg, transparent $edgeSize, darken($background, 10%) 0) bottom left;
                background-size: 50% 50%;
                background-repeat: no-repeat;
            }*/
        }

        @else {
            background: transparent;
            background: linear-gradient(135deg, $background $edgeSize, transparent 0) top left,
            linear-gradient(-135deg, $background $edgeSize, transparent 0) top right,
            linear-gradient(-45deg, $background $edgeSize, transparent 0) bottom right,
            linear-gradient(45deg, $background $edgeSize, transparent 0) bottom left;
            background-size: 52% 52%;
            background-repeat: no-repeat;

        }
    }

}
