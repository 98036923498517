/*******************************************************************************
 *
 * Global Styles + Resets
 *
 ******************************************************************************/


// Mixins and Placeholder Classes
//--------------------------------------------------

%m-reset {
     margin: 0;
 }



// Body Styles
//--------------------------------------------------
body {
    background-color: $background--module;
    color: $color--text--base;


    // Padding if fixed posed Userbar is used
    //--------------------------------------------------
    &.has-userbar {
        padding-top: $userbar__height;
    }
}

// Headlines
//--------------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
    @extend %m-reset;
}



// Text Elements
//--------------------------------------------------
p {
    @extend %m-reset;
}


i {
    font-style: normal;
}
