/*******************************************************************************
 *
 * Shareform Directive Stylings
 *
 ******************************************************************************/

.#{$prjPrefix}-share {
    text-align: center;

    &__link-caption {
        @include button-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, 0);
        text-align: center;
        font-weight: $bold;
    }

}
