/*******************************************************************************
 *
 * Shade Function
 *
 * @param {Color} $color - color to shade
 * @param {Number} $percentage - percentage of `$color` in returned color
 * @return {Color}
 *
 ******************************************************************************/

@function shade($color, $percentage) {
    @return mix($color, #000, $percentage);
}
