/*******************************************************************************
 *
 * Comment Component for Popup
 *
 ******************************************************************************/


.#{$prjPrefix}-comment {

    // Uploaded Image
    //--------------------------------------------------
    &__image {
        float: left;

        max-width: 150px;
        height: auto;
        margin-right: 10px;
    }

    // Author Styles
    //--------------------------------------------------
    &__author {
        //float: left;
        margin-right: 5px;

        &__image {
        }

        &__name {
            vertical-align: top;

            &#{&} {
                .ct-wall-popup__section--answer & {
                    color: inherit;
                    margin-bottom: 1em;
                }
                font-size: 1em;
            }
        }
    }

    // Text of Comment
    //--------------------------------------------------
    &__content {
    }


}
