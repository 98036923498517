/*******************************************************************************
 *
 * Unified Barnavigation Like in popup
 *
 ******************************************************************************/

.#{$prjPrefix}-bar-nav {
    background-color: $background--barnav;
    color: $color--barnav;

    border-bottom: 1px solid #eeeeee;

    // Single item
    //--------------------------------------------------
    &__item {
        text-transform: uppercase;
        color: inherit
    }
}
