/*******************************************************************************
 *
 * UGC Wall Item (Story)
 *
 ******************************************************************************/

.#{$prjPrefix}-#{$moduleName}-item {
    background-color: $blue-light;

    /*------------------------------------------------------*\
         Media Content
    \*------------------------------------------------------*/
        &__media {

        }

        // UGS Image
        //--------------------------------------------------
        &__image {
            display: block;
            width: 100%;
            height: auto;
        }


    /*------------------------------------------------------*\
         Text Content
    \*------------------------------------------------------*/

        &__text {
            font-weight: bold;
        }

        &__content {
            padding: $grid-gutter-width;

            /*------------------------------------------------------*\
                 Comment Styles in Teaser Story
            \*------------------------------------------------------*/
            @include m(answer) {
                background-color: $blue-med;
                color: $color--answer;


                // Inner Elements
                //--------------------------------------------------
            }
        }

        // Contains Couters + Author
        //--------------------------------------------------
        &__header {
            @include clearfix;
        }

        &__author {
            //float: left;
        }

        &__counters {
            float: right;
        }


    /*------------------------------------------------------*\
         Hover
    \*------------------------------------------------------*/
        &__hover {

            &__icon {
                position: absolute;
                top: 50%;
                left: 50%;

                transform: translate(-50%, -50%);

            }
        }


}
