/*******************************************************************************
 *
 * Disclaimer Stylings
 *
 ******************************************************************************/

.#{$prjPrefix}-disclaimer {

    color: $color--text--base;
    text-align: center;

    font-size: $font-size-base;
    line-height: (13/11);
    padding-bottom: 2rem;

    &--white {
        color: #FFFFFF;

        a {
            color: #FFFFFF;
        }
    }

    &--emphasized, a {
        color: $emphasize-color;
    }

    a {
        text-decoration: underline;
    }

    // Left Aligned Disclaimer
    //--------------------------------------------------
    &--left {
        text-align: left;
    }

    @include m('break') {
        word-break: break-all;
    }
}
