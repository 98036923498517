/*******************************************************************************
 *
 * Action Bar Component
 *
 * Signup Button + Filter etc. Bar
 *
 ******************************************************************************/


.#{$prjPrefix}-wall-actionbar {
    padding-top: $grid-gutter-width;
}
