/*******************************************************************************
 *
 * Main Navigation atop
 *
 ******************************************************************************/


.#{$prjPrefix}-main-nav {


    background-color: $background--nav;
    border-bottom: $nav__width--border solid $nav__color--border;

    // Inner Wrapper
    //--------------------------------------------------
    @include e(inner) {
        padding: 1rem 0;
        height: $nav__height;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }


    // Link List
    //--------------------------------------------------
    @include e(links) {
        color: #fff;
        font-weight: $bold;
        margin-left: 2rem;
    }

}
