/*******************************************************************************
 *
 * Converts the Selector List you get with to a String
 * when use & Selector you'll get a List instead of a string since SASS 3.4
 *
 ******************************************************************************/

@function selectorToString($selector) {

    $selector: inspect($selector); //cast to string
    $selector: str-slice($selector, 2, -2); //remove bracket

    @return $selector;
}
