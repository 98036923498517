/*******************************************************************************
 *
 * User Component for User Name + Profile pic
 *
 ******************************************************************************/


.#{$prjPrefix}-user {
    //display: inline-block;
    line-height: 1;
    margin-bottom: .625em;

    // Profile Picture
    //--------------------------------------------------
    &__image {
        display: inline-block;
        vertical-align: middle;

        width: 50px;
        height: 50px;
        margin-right: .5em;

        &--small {
            width: 30px;
            height: 30px;
            margin-right: .2em;
        }
    }



    // User Name
    //--------------------------------------------------
    &__name {
        font-size: 1.4em;
        line-height: 1;
        color: $color--user;
        font-weight: 200;
        //font-weight: $bold;

        display: inline-block;
        vertical-align: bottom;
    }

    &--answer &__name {
        color: $color--answer;
    }

}

