/*******************************************************************************
 *
 * Utilities File
 *
 ******************************************************************************/



// Link that is a Block Element and has no decorarion
//--------------------------------------------------
.ut-block-link {
    display: block;
    text-decoration: none;

    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

// Quick Position relative setting for Containers
//--------------------------------------------------
.ut-posr {
    position: relative;
}

.ut-ttu {
    text-transform: uppercase;
}

// Colors Text
//--------------------------------------------------
.ut-emphasized {
    color: $brand-primary;
}


.ut-clearfix {
    @include clearfix;
}

// Fixes Icons with pull-left or right
//--------------------------------------------------
.ut-bs-pull-fixer {
    padding-top: 3px;
}



.ut-no-mb {
    margin-bottom: 0 !important;
}

.ut-add-mb {
    margin-bottom: $grid-gutter-width !important;
}


// 0.5 md grid row
//--------------------------------------------------
.col-md-0p5 {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding


    @include Media(md, min) {
        float: left;
        padding-left: ($grid-gutter-width / 2);
        padding-right: ($grid-gutter-width / 2);
        width: (100%/($grid-columns * 2));
    }
}

/*------------------------------------------------------*\
     Grid Padding Resers
\*------------------------------------------------------*/

.ut-grid-reset {
    @include resetGridGutter();

    &--mobile {
        @include resetGridGutter(true);
    }
}
// reset Grid Padding in this special case, so that the container takes the whole width
//--------------------------------------------------
> .grid__mobile-reset {

    @include Media(sm, max) {
        padding: 0;
    }
}



/**
 * To reduce the gutter by half (in our case 5px) on a side
 * Classes: small-gutter--left
 *          small-gutter--right
 * Usage: Set on the col Container you want to change to change it's padding
 **/
.small-gutter {
    &--left {
        @include Media(md, min) {
            padding-left: ($grid-gutter-width/4);
        }
    }

    &--right {
        @include Media(md, min) {
            padding-right: ($grid-gutter-width/4);
        }
    }
}

.fa {
    &.fa-left {
        position: absolute;
        top: 50%;
        //bottom: 0;
        left: $padding-base-horizontal;
        //height: $font-size-base;
        transform: translateY(-50%);
    }

    vertical-align: middle;
}




// Centering (w Flexbox)
//--------------------------------------------------
.ut-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}


// SocialMedia Text Colors
//--------------------------------------------------
.ut-smc {
    &--facebook {
        color: $brand-fb;
    }
    &--twitter {
        color: $brand-tw;
    }
    &--instagram {
        color: $brand-instagram;
    }
}


// Layout Utilities
//--------------------------------------------------
.ut-d {

    // Inline Block
    //--------------------------------------------------
    &--ib {
        display: inline-block !important;
    }
}

// VerticalAlignments
//--------------------------------------------------
.ut-va {

    &--m {
        vertical-align: middle;
    }
}


// Text Alignments
//--------------------------------------------------
.ut-ta {

    &--c {
        text-align: center;
    }
}

/*------------------------------------------------------*\
     Text Spacers for floating text
\*------------------------------------------------------*/
.ut-text-sp {

    // spacing down
    //--------------------------------------------------
    &--b {
        margin-bottom: 1em;
    }


    // spacing up
    //--------------------------------------------------
    &--t {
        margin-top: 1em;
    }
}


/*------------------------------------------------------*\
     Spacings Remover Classes
\*------------------------------------------------------*/

    // Removes Padding
    //--------------------------------------------------
    .ut-no-pd {
        padding: 0 !important;

        // Vertical
        //--------------------------------------------------
        &--y {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        // Horizontal
        //--------------------------------------------------
        &--x {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        // Top
        //--------------------------------------------------
        &--t {
            padding-top: 0 !important;
        }

        // Bottom
        //--------------------------------------------------
        &--b {
            padding-bottom: 0 !important;
        }

        // Left
        //--------------------------------------------------
        &--l {
            padding-left: 0 !important;
        }

        // Right
        //--------------------------------------------------
        &--r {
            padding-right: 0 !important;
        }
    }
