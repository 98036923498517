/*******************************************************************************
 *
 * CS Photo Selector
 * @author: Carson Shold (@cshold)
 *
 ******************************************************************************/


.html #ct-services--csphotoselector #CSPhotoSelector, #CSPhotoSelector {
    display: none;
    width: 100%;
    height: 100%;
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    padding-top: 5%;
    z-index: 1630;
    color: #333;
    font-family: "lucida grande", tahoma, verdana, arial, sans-serif;
    font-size: 11px;
    background: rgba(0,0,0,.3);
}
#CSPhotoSelector,
#CSPhotoSelector * {
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    line-height: normal;
}
#CSPhotoSelector a,
#CSPhotoSelector a:active,
#CSPhotoSelector a:hover,
#CSPhotoSelector a:visited {
    text-decoration: none;
    color: #3B5998;
}
#CSPhotoSelector img {
    max-width: none;
}
#CSPhotoSelector p,
#CSPhotoSelector ul,
#CSPhotoSelector li {
    margin: 0;
    padding: 0;
}
#CSPhotoSelector li {
    list-style-type: none;
}
#CSPhotoSelector .CSPhotoSelector_clearfix:before,
#CSPhotoSelector .CSPhotoSelector_clearfix:after {
    content: "";
    display: table;
}
#CSPhotoSelector .CSPhotoSelector_clearfix:after { clear: both; }
#CSPhotoSelector .CSPhotoSelector_clearfix { zoom: 1; }
#CSPhotoSelector .CSPhotoSelector_dialog {
    width: 628px;
    padding: 10px;
    margin: 0 auto;
    position: relative;
    background: rgba(82, 82, 82, 0.7);
    overflow: hidden;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
}
#CSPhotoSelector .CSPhotoSelector_dialog #CSPhotoSelector_buttonClose {
    position: absolute;
    right: 18px;
    top: 15px;
    font-size: 16px;
}
#CSPhotoSelector .CSPhotoSelector_dialog #CSPhotoSelector_buttonClose,
#CSPhotoSelector .CSPhotoSelector_dialog #CSPhotoSelector_buttonClose:active,
#CSPhotoSelector .CSPhotoSelector_dialog #CSPhotoSelector_buttonClose:hover,
#CSPhotoSelector .CSPhotoSelector_dialog #CSPhotoSelector_buttonClose:visited {
    color: #fff;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_form {
    border: 1px solid #3B5998;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_header {
    padding: 7px 10px;
    background: #6D84B4;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_content {
    background: #fff;
    padding: 10px;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_searchContainer {
    margin-top: 10px;
    padding: 10px;
    background: #f1f1f1;
    border: solid #cacaca;
    border-width: 1px 0;
    font-weight: bold;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_searchContainer .CSPhotoSelector_selectedCountContainer {
    float: left;
    padding: 3px 0;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_searchContainer #CSPhotoSelector_backToAlbums {
    float: right;
    padding: 3px 0;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_photosContainer {
    margin: 10px 0 0;
}

#CSPhotoSelector .CSPhotoSelector_content {
    height: 400px;
}

#CSPhotoSelector .CSPhotoSelector_dialog .CSAlbum_container .CSPhotoSelector_album {
    position: relative;
    display: inline-block;
    width: 185px;
    margin: 0 8px 14px;
    cursor: pointer;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSAlbum_container .CSPhotoSelector_albumWrap {
    position: relative;
    width: 175px;
    padding: 4px;
    border: 1px solid #ccc;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSAlbum_container .CSPhotoSelector_albumWrap div {
    height: 120px;
    overflow: hidden;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSAlbum_container .CSPhotoSelector_album .CSPhotoSelector_photoAvatar {
    display: block;
    max-width: 100%;
    border: none;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhoto_container .CSPhotoSelector_photo {
    position: relative;
    display: inline-block;
    border: 1px solid #ccc;
    background: #fff;
    padding: 4px;
    margin: 0 5px 3px;
    height: 96px;
    width: 127px;
    overflow: hidden;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhoto_container .CSPhotoSelector_photo:hover,
#CSPhotoSelector .CSPhotoSelector_dialog .CSAlbum_container .CSPhotoSelector_albumWrap:hover,
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhoto_container .CSPhotoSelector_photo.CSPhotoSelector_photoSelected {
    background: #f0f0f0;
    border-color: #3B5998;
    cursor: pointer;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhoto_container .CSPhotoSelector_photo span {
    position: relative;
    display: block;
    overflow: hidden;
    height: 100%;
    width: 100%;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhoto_container .CSPhotoSelector_photo .CSPhotoSelector_photoAvatar {
    position: relative;
    display: block;
    width: 120%;
    left: -10%;
    margin: 0 auto;
    border: none;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_photosContainer .CSPhotoSelector_album .CSPhotoSelector_photoName {
    display: block;
    width: 100%;
    font-weight: bold;
    text-align: left;
    font-size: 12px;
    padding: 5px 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_photosContainer .CSPhotoSelector_photo:hover .CSPhotoSelector_photoSelect {
    display: block;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_photosContainer .CSPhotoSelector_photo.CSPhotoSelector_photoDisabled {
    cursor: default;
    opacity: .5;
    filter: alpha(opacity=50);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_photosContainer .CSPhotoSelector_photo.CSPhotoSelector_photoDisabled:hover .CSPhotoSelector_photoSelect {
    display: none;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_photosContainer .CSPhotoSelector_photo.CSPhotoSelector_photoFiltered {
    display: none;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_footer {
    padding: 8px 10px;
    background: #F2F2F2;
    border-top: 1px solid #CCC;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_footer #CSPhotoSelector_pagePrev,
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_footer #CSPhotoSelector_pageNext {
    float: left;
    height: 21px;
    width: 22px;
    overflow: hidden;
    text-indent: -999em;
    background: transparent url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG4AAAAVCAMAAACQYCvLAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowMTgwMTE3NDA3MjA2ODExQjFBNEZGODRGOUJCNzJGNSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo2Q0E2REM2Njg5M0QxMUUwOTIzMEUzOTIwRTVFOUEyMCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo2Q0E2REM2NTg5M0QxMUUwOTIzMEUzOTIwRTVFOUEyMCIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjA0ODAxMTc0MDcyMDY4MTFCMUE0RkY4NEY5QkI3MkY1IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAxODAxMTc0MDcyMDY4MTFCMUE0RkY4NEY5QkI3MkY1Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+hORTbQAAABVQTFRFp6ena2xrl5eXxsbG8fHx4uTkAAAAc1YJ7gAAAAd0Uk5T////////ABpLA0YAAABsSURBVHja7NbLCoBADEPRpFX//5Pd6xXbTRGZrEMPwzwYBSYxne6BUeyQyA2Sne7i/ssZR+iJM3Kqc0ZOzBk5dTgjJ+KMnHqckdOdK3QrnHGErpyRk769utm9mz2Zs/duvZmLe+FG/yqnAAMA8aInMQS6idEAAAAASUVORK5CYII=') no-repeat 0 0;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_footer #CSPhotoSelector_pagePrev {
    background-position: -44px 0;
    margin-right: 2px;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_footer #CSPhotoSelector_pageNext {
    margin-right: 6px;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_footer #CSPhotoSelector_pagePrev.CSPhotoSelector_disabled {
    background-position: -66px 0;
    cursor: default;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_footer #CSPhotoSelector_pageNext.CSPhotoSelector_disabled {
    background-position: -22px 0;
    cursor: default;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_footer .CSPhotoSelector_pageNumberContainer {
    float: left;
    padding: 4px 0 0;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_footer #CSPhotoSelector_buttonOK,
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_footer #CSPhotoSelector_buttonCancel {
    display: none;
    float: right;
    background: #3B5998;
    color: #fff;
    font-weight: bold;
    padding: 4px 20px 5px 20px;
    border: 1px solid;
    border-color: #D9DFEA #0E1F5B #0E1F5B #D9DFEA;
}
#CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_footer #CSPhotoSelector_buttonCancel {
    display: block;
    border: 1px solid;
    border-color: #999 #999 #888 #999;
    background-color: #eee;
    color: #333;
    margin-right: 8px;
}


/* show/hide album and picture containers */
#CSPhotoSelector_loader {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url('data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==') center center no-repeat rgba(255,255,255,0.5);
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
}
.CSPhotoSelector_wrapper {
    position: absolute;
    top: 43px;
    left: 670px;
    width: 606px;
    overflow: auto;

    -webkit-transition:	left 0.3s ease-in-out;
    -moz-transition:	left 0.3s ease-in-out;
    -o-transition:		left 0.3s ease-in-out;
    transition:			left 0.3s ease-in-out;
}
/* active photo container */
.CSPhoto_container_active {
    left: 11px;
}
@media (max-width: 640px) {
    #CSPhotoSelector .CSPhotoSelector_dialog {
        padding: 0;
        background: none;
        width: 100%;
    }

    #CSPhotoSelector .CSPhotoSelector_dialog #CSPhotoSelector_buttonClose {
        top: 5px;
    }

    #CSPhotoSelector .CSPhotoSelector_dialog .CSAlbum_container .CSPhotoSelector_album {
        width: 133px;
    }

    #CSPhotoSelector .CSPhotoSelector_dialog .CSAlbum_container .CSPhotoSelector_albumWrap {
        width: 124px;
    }

    #CSPhotoSelector .CSPhotoSelector_content {
        height: 300px;
        overflow: scroll;
    }

    #CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_content {
        padding: 10px 0;
    }

    .CSPhoto_container_active {
        left: 0;
    }

    .CSPhotoSelector_wrapper {
        width: 100%;
        top: 31px;
    }

    #CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_form {
        border: none;
    }

    #CSPhotoSelector .CSPhotoSelector_dialog .CSPhotoSelector_footer {
        position: relative;
        z-index: 1;
    }
}
