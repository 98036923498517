/*******************************************************************************
 *
 * Returns the Place Holder Text for PHP Substitution
 * @return string (TODO: getThemeVar)
 *
 ******************************************************************************/

@function getThemeVar($variable) {
    @return "%#{$variable}%"
}

@function getSassFunc($func, $params...) {
    @return "::#{$func}(#{$params})::"
}
