/*******************************************************************************
 *
 * Include File of different Global Components
 *
 ******************************************************************************/

// Prefix for Themes Prj
//--------------------------------------------------
$prjPrefix: 'ct';       //for Campaigning Themes?

@import "utilities";  //Reset
@import "global";  //Reset

@import "_components/directives";

@import "_components/disclaimer";
@import "_components/content";
@import "_components/loading-spinner";
@import "_components/social-connect.scss";
@import "_components/main-logo";
@import "_components/checkbox";
@import "_components/checkbox-disclaimer";


// Global Blocks
//--------------------------------------------------
@import "_components/_blocks/main-nav";
