/*******************************************************************************
 *
 * LAyout + grid extends
 *
 ******************************************************************************/


.container {

    @include xsmax() {
        width: 100%;
    }
}
