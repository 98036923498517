/*******************************************************************************
 *
 * Video Thumbnail Component.
 * as used in UGC Item
 *
 ******************************************************************************/


.#{$prjPrefix}-video-thumb {
    position: relative;

    font-size: 7rem;                // Base Component Size

    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        width: 1em;
        height: 1em;
        margin: auto;

        color: rgba($color--play-icon, 0.75);
        font-weight: $bold;
    }
}
