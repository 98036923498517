$color-state-new: #428BCA;
$color-state-blocked: #D9534F;
$color-state-active: transparent;

.#{$prjPrefix}-state-class {

    &--new,
    &--blocked {
        position: relative;
        pointer-events: none;

        &:before {
            display: block;
            content: '';

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;

            border: 2px dashed;
            border-radius: $base-radius;
        }

        > .#{$prjPrefix}-item {
            position: relative;
            z-index: 1;
            opacity: .6;
        }
    }

    &--new &__message,
    &--blocked &__message {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 3;

        text-align: center;

        font-size: 12px;
        padding: .8em .5em;

        border-top-left-radius: $base-radius;
        border-top-right-radius: $base-radius;
    }

    &--new:before {
        border-color: $color-state-new;
    }
    &--new &__message {
        background-color: $color-state-new;
        color: white;
    }
    &--blocked:before {
        border-color: $color-state-blocked;
    }
    &--blocked &__message {
        background-color: $color-state-blocked;
        color: white;
    }
}
