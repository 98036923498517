/*******************************************************************************
 *
 * Social Connect Component
 *
 * If the User is connected via a social service (FB, LinkedIn, etc…)
 * You see the Icon of the Service, on its Brand Color, the Username and his/her
 * E - Mail (if avalailable )
 *
 * @Usage
 *
 ******************************************************************************/


.#{$prjPrefix}-sc-connect {
    display: flex;
    flex-shrink: 0;
    width: 100%;

    height: $input__height--med;
    margin-bottom: 1.5rem;


    // Source Data e.g: Facebook
    //--------------------------------------------------
    @include e(source) {
        display: flex;

        align-items: center;
        justify-content: center;

        min-width: 3rem;

        @include e(icon) {
            font-size: 2rem;
        }


    }

    // Person Data
    //--------------------------------------------------
    @include e(person) {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: center;
        flex-shrink: 0;
        flex-grow: 1;

        line-height: 1;
        padding: 0 ($grid-gutter-width / 2);

        max-width: 100%;

        @include e(name) {
        }
    }

    // E - Mail
    //--------------------------------------------------
    //  optional email with brackets
    //--------------------------------------------------
    @include e(email) {

        // Brackets
        //--------------------------------------------------
        &::before,
        &::after {
            display: inline-block;
        }


        &::before {
            content: '<';
        }

        &::after {
            content: '>';
        }
    }


    /*------------------------------------------------------*\
         Color Modifier
    \*------------------------------------------------------*/
        // TODO: Generate Source modifier via map or array
        //--------------------------------------------------
        @include m(facebook) {

            @include e(source) {
                color: #fff;
                background-color: $brand-fb;
            }
        }
}
