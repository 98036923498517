/*******************************************************************************
 *
 * Mixin that puts Container over Parent
 *
 ******************************************************************************/


@mixin overlay-content() {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
