/*******************************************************************************
 *
 * Image Cropper Styles
 *
 ******************************************************************************/

    img {
        &.img-landscape,
        &.img-portrait {
            position: absolute;
            top: -9999px;
            left: -9999px;
            right: -9999px;
            bottom: -9999px;

            margin: auto;
        }
        &.img-landscape {
            min-width: 0;
            width: auto;
            max-width: none;

            min-height: 0;
            height: 100%;
            max-height: none;
        }
        &.img-portrait {
            min-width: 0;
            width: 100%;
            max-width: none;

            min-height: 0;
            height: auto;
            max-height: none;
        }
    }
