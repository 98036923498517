/*******************************************************************************
 *
 * Buttons Extend
 *
 ******************************************************************************/

@mixin button-variant--cta($color, $background, $border, $color-hover, $background-hover, $border-hover) {
    color: $color;
    background-color: $background;
    border: 1px solid $border;

    box-shadow: inset 0px -2px 0px rgba(black, .2);


    &:focus,
    &.focus {
        color: $color-hover;
        background-color: $background-hover;
        border-color: $border-hover;
        box-shadow: none;
    }
    &:hover {
        color: $color-hover;
        background-color: $background-hover;
        border-color: $border-hover;
        box-shadow: none;
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        color: $color;
        background-color: $background;
        border-color: $border;

        &:hover,
        &:focus,
        &.focus {
            color: $color;
            background-color: $background;
            border-color: $border;
        }
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        background-image: none;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            color: $color;
            background-color: $background;
            border-color: $border;
        }
    }

    .badge {
        color: $background;
        background-color: $color;
    }
}


// Generel Button Styles
//--------------------------------------------------
.btn {
    position: relative;
    transition: background-color .2s;
    border: none;       //to secure equal height with input fields set in Variables;
    line-height: 1;
    max-height: $input__height--base;


    &-lg {
        max-height: $input__height--med;
    }

    // Underlined Modifier
    //--------------------------------------------------
    &--underlined {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

//.btn-xl {
//    @include button-size($padding-xl-vertical, $padding-xl-horizontal, $font-size-xl, $line-height-large, $btn-border-radius-large);
//}


/*------------------------------------------------------*\
     Different Button Flavours
\*------------------------------------------------------*/

.btn-facebook {
    @include button-variant(#fff, $brand-fb, $brand-fb);
}


.btn-twitter {
    @include button-variant(#fff, $brand-tw, $brand-tw);

}

.btn-whatsapp {
    @include button-variant(#fff, $brand-whatsapp, $brand-whatsapp);

}


// Special FB Connect button with Gradient and rounded edges
//--------------------------------------------------
.btn-facebook-connect {
    font-size: $button__fontsize--mobile--fb;
    font-weight: $bold;
    line-height: 1;

    max-height: $button__height--med;

    @include button-variant(#fff, $brand-fb, $brand-fb);
    @include btn-gradient-apply($brand-fb--light, $brand-fb);

    padding-top: $button__padding-y--base--mobile--fb;
    padding-bottom: $button__padding-y--base--mobile--fb;

    @include Media(md, min) {
        font-size: $button__fontsize--fb;

        padding-top: $button__padding-y--base--fb;
        padding-bottom: $button__padding-y--base--fb;
    }

}




// New Theme Buttons
//--------------------------------------------------
    .btn--cta {
        @include button-variant--cta($btn-cta-color, $btn-cta-bg, $btn-cta-bg, $btn-cta-color, $btn-cta-border, $btn-cta-border);
    }

    .btn--submit {
        @extend .btn-lg;

        @include button-variant($btn--submit__color, $btn--submit__background, $btn--submit__background);
        border: 1px solid $btn--submit__background;
        font-weight: $bold;
        border-radius: $btn--submit__radius;
        text-transform: uppercase;

    }


    .btn--outline {
        @include button-variant($btn--outline__color, transparent, $btn--outline__color);
        border: 1px solid $btn--outline__color;

        &:hover {
            background-color: $btn--outline__color;
            color: $background--signup;
        }
    }



/**
 * Widget Specific Buttons
 **/

// Black Transparent Close Button with red hover
.btn-close {
    @include button-variant(#fff, rgba(0,0,0,.6), rgba(0,0,0,.6));

    &:hover,
    &:active {
        background-color: $brand-danger;
        border-color: $brand-danger;
    }

    &.btn-sm {
        padding: 3px 8px;

        .fa {
            font-size: 12px;
        }
    }
}

// Text Button
//--------------------------------------------------
.btn--text {
    @include button-variant($btn-text, transparent, transparent);
    border-radius: 0;

    &--cta {
        @include button-variant($background--btn--cta, transparent, transparent);
    }
}

.btn--bar-indicator {
    position: relative;
    transition: color .3s;

    &::before {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;

        transform: translateY(100%);
        transition: transform $transition--duration--base ease-out;
        transform-origin: center bottom;

        background-color: $color--indicator;
    }

    &:hover,
    &:active,
    &.is-active{
        color: $color--indicator;

        &::before {
            transform: translateY(0);
        }
    }


    &:active,
    &:focus,
    &is-active:active {
        &::before {
            transform: translateY(0) scaleY(2.5);
        }
    }

}

/**
 * Link with Underline.
 * on hover: remove line
 **/

.link--underlined {
    text-decoration: underline;
    font-size: 12px;
    margin-bottom: 5px;

    &:hover {
        text-decoration: none;
    }
}

// Color of Link Button
//--------------------------------------------------
.btn-link {
    color: $color--link--base;

    &:hover {
        color: getSassFunc(mix, $color--link--base, #000, 10);

    }

    @include m(underlined) {
        color: $btn-text;
        font-size: 12px;
        text-decoration: underline;
        line-height: floor($input__height--base - ($padding-base-vertical * 2));


        &:hover {
            color: inherit;
        }

        @at-root .btn-lg#{&} {
            line-height: floor($input__height--med - ($padding-large-vertical * 2));
        }
    }
}



/*------------------------------------------------------*\
     Width Modifiers
\*------------------------------------------------------*/
.btn {

    @include m(280) {
        width: 100%;

        @include smmin() {
            width: 280px;
        }
    }
}


