/*******************************************************************************
 *
 * Form Stylings
 *
 ******************************************************************************/


// Has Feedback Styles
//--------------------------------------------------

.form-control-feedback {
    color: $input-color;

    // Borderl
    //--------------------------------------------------
    &::after {              // after Used because of Font Awesome
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        bottom: 0;

        margin: 4px 0;
        border-left: 1px solid rgba($selecty__text-color, 0.2);
    }


     @at-root .form-group-lg #{&} {
         width: $input-height-large;
         height: $input-height-large;
         line-height: $input-height-large;
    }

    // Clickable modifier
    //--------------------------------------------------
    &--clickable {
        pointer-events: auto;

        &:hover {
            cursor: pointer;
        }
    }
}


/*------------------------------------------------------*\
     Input Group Adaptions
\*------------------------------------------------------*/
// Display as table-cell
// -------------------------
.input-group-addon,
.input-group-btn,
.input-group .form-control {

    &:first-child:not(:last-child) {
        border-top-left-radius: $border-radius-base;
        border-bottom-left-radius: $border-radius-base;


    }


}

.form-group-lg.input-group .form-control:first-child:not(:last-child) {
    border-top-left-radius: $border-radius-large;
    border-bottom-left-radius: $border-radius-large;
}

.form-control-static {
    vertical-align: middle;
    &--facebook {
        .form-group-lg &, & {
            height: auto;
        }
    }
}

.form-control {
    resize: none;
}


// textarea Component
//--------------------------------------------------
.form-control-textarea {


    // Size Components
    //--------------------------------------------------
    @include m(lg) {
        min-height: 15rem;
    }
}


/*------------------------------------------------------*\
     Special Element coloring
\*------------------------------------------------------*/
legend {
    color: $color--text--base;
}
