/*******************************************************************************
 *
 * Component for Voting, Comment + Like
 *
 ******************************************************************************/


.#{$prjPrefix}-actions {
    clear: both;

    &__content {
        padding: $grid-gutter-width $grid-gutter-width 0;
    }
}

/*------------------------------------------------------*\
     Actions Overview + List
\*------------------------------------------------------*/
.#{$prjPrefix}-actions-overview {
    @extend .list-inline;
    margin-bottom: 0;
    border-bottom: 1px solid mix(#fff, $color--text--light, 80%);

    .ct-wall-popup__section--answer & {
        margin-top: 1em;
        border: none;
    }

    &__item {
        position: relative;
        overflow: hidden;

        padding-top: 5px;
        padding-bottom: 5px;

        
        // Contains new List with Overview Items
        //--------------------------------------------------
        &--sublist {
            padding: 0;
        }
        
        // Defines Position
        //--------------------------------------------------
        &--right {
            float:  right;
        }
    }

    .btn--text {
        color: $color--text--base;

        &:hover {
            color: #06a0dd;
        }

        .ct-wall-popup__section--answer & {
            &, &:hover {
                color: $color--answer;
            }
        }
    }
}
