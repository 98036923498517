/*******************************************************************************
 *
 * Function that calculates the Viewport Width unit from a target Value
 *
 ******************************************************************************/


@function get-vw($target, $viewport: 1300) {
    $vw-context: ($viewport * 0.01) * 1px;
    @return ($target / $vw-context) * 1vw;
}
