/*******************************************************************************
 *
 * Header Component for Wall-like Modules (e.g.: UGS or Commitment)
 *
 ******************************************************************************/


.#{$prjPrefix}-wall-header {
    color: $color--header--base;

    background: $background--header no-repeat center;
    background-size: cover;

    padding: 30px 0;
    text-align: center;

    @include smmin {
        padding: 60px 0;
    }

    // Styles if Header Contains Image
    //--------------------------------------------------
    &--has-image {
        color: $color--header--img;

        @include smmin {
            min-height: 250px;
        }
    }

    // Module Title
    //--------------------------------------------------
    &__title {
        line-height: (48 / 40);

        @include smmin {
            font-size: 40px;
        }
    }

    // Module Description Text
    //--------------------------------------------------
    &__text {
        line-height: (36 / 30);
        margin-bottom: 10px;

        @include smmin {
            font-size: 30px;
        }
    }

    
    // Modifier if Header has Image set
    //--------------------------------------------------
    &--has-image {
        color: $color--header--img;
    }
}
